import React, { useState } from "react";
import Draggable from "react-draggable";
import "swiper/css";

let zIndexCounter = 1;

export default function Site(props) {
  const [zIndex, setZIndex] = useState(1);
  const [toggleView, setToggleView] = useState(1);

  const onStart = () => {
    zIndexCounter += 1;
    setZIndex(zIndexCounter);
  };
  return (
    <Draggable
      // bounds="#hero"
      defaultPosition={{ x: props.x, y: props.y }}
      onStart={onStart}
    >
      <div className="window" style={{ zIndex: zIndex }}>
        <div
          style={{
            display: "flex",
            minWidth: "200px",
            justifyContent: "space-between",
            cursor: "move",
            padding: "0.2em",
          }}
        >
          <span style={{ fontWeight: "bold" }}>{props.title}</span>
          <span
            onClick={setToggleView.bind(this, !toggleView)}
            style={{
              fontWeight: "bold",
              cursor: toggleView ? "pointer" : "crosshair",
            }}
            className="material-symbols-outlined toggle"
          >
            {toggleView ? "remove" : "add"}
          </span>
        </div>

        {toggleView ? (
          <div>
            {props?.vids ? (
              <video
                src={"assets/imgs/" + props.vids[0]}
                loop
                muted
                autoPlay
              ></video>
            ) : null}

            {!props?.vids && props?.imgs ? (
              <img src={"assets/imgs/" + props.imgs[0]} />
            ) : null}

            {props?.iframe ? (
              <iframe
                frameBorder={0}
                src={props.url}
                name={props.title}
                title={props.title}
                allowFullScreen
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; clipboard-read; clipboard-write"
              />
            ) : null}

            <a href={props?.url} target="_blank">
              <h6> {props.urlClean}</h6>
            </a>
          </div>
        ) : null}
      </div>
    </Draggable>
  );
}
