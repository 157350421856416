import { useState } from "react";
import Draggable from "react-draggable";

const fonts = [
  "BodoniModa",
  "TINY5x3",
  "PoiretOne",
  "Vonique",
  // "NeueHaasDisplayRoman",
];

const Digital = props => {
  let time = new Date().toLocaleTimeString();
  const [font, setFont] = useState(fonts[0]);

  const [ctime, setTime] = useState(time);
  const UpdateTime = () => {
    time = new Date().toLocaleTimeString();
    setTime(time);
  };
  setInterval(UpdateTime);
  return (
    <Draggable bounds="body" defaultPosition={{ x: props.x, y: props.y }}>
      <div className="window">
        <span
          onClick={() =>
            setFont(fonts[Math.floor(Math.random() * fonts.length) + 1])
          }
          className="digital"
          style={{
            fontFamily: font,
            // fontFamily: "PoiretOne",
            // fontFamily: "BodoniModa",
          }}
        >
          {ctime}
        </span>
      </div>
    </Draggable>
  );
};
export default Digital;
