import React, { useEffect, useState } from "react";
import Clock from "react-clock";
import Draggable from "react-draggable";

export default function Clockk(props) {
  const [value, setValue] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Draggable bounds="body" defaultPosition={{ x: props.x, y: props.y }}>
      <div className="clock window">
        <Clock
          value={value}
          size={190}
          minuteHandWidth={2.5}
          hourHandWidth={2.5}
          secondHandWidth={2.5}
        />
      </div>
    </Draggable>
  );
}
