import React, { useEffect } from "react";
import "../styles/mickey.css";
import Draggable from "react-draggable";

export default function Mickey(props) {
  useEffect(() => {
    const $hours = document.getElementById("hours");
    const $minutes = document.getElementById("minutes");

    const intervalId = setInterval(() => {
      const hours = new Date().getHours();
      const minutes = new Date().getMinutes();

      if (hours >= 6 && hours < 12) {
        $hours.className = "left";
      } else {
        $hours.className = "right";
      }

      if (minutes <= 30) {
        $minutes.className = "back left";
      } else {
        $minutes.className = "left";
      }

      set($hours, 30 * hours);
      set($minutes, 6 * minutes);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  function set($elmt, deg) {
    if ($elmt.id === "hours" && deg < 180) {
      deg = deg - 5;
    }

    if ($elmt.id === "minutes" && deg < 180) {
      deg = deg + 5;
    } else {
      deg = deg - 5;
    }

    $elmt.style.transform = "rotate(" + deg + "deg)";
    $elmt.style.webkitTransform = "rotate(" + deg + "deg)";
  }

  return (
    <div className="wrapperMickey">
      <Draggable bounds="body" defaultPosition={{ x: props.x, y: props.y }}>
        <div
          className="window"
          style={{
            display: "flex",
          }}
        >
          <div id="watch">
            <div className="digit">
              <span>1</span>
            </div>
            <div className="digit">
              <span>2</span>
            </div>
            <div className="digit">
              <span>3</span>
            </div>
            <div className="digit">
              <span>4</span>
            </div>
            <div className="digit">
              <span>5</span>
            </div>
            <div className="digit">
              <span>6</span>
            </div>
            <div className="digit">
              <span>7</span>
            </div>
            <div className="digit">
              <span>8</span>
            </div>
            <div className="digit">
              <span>9</span>
            </div>
            <div className="digit">
              <span>10</span>
            </div>
            <div className="digit">
              <span>11</span>
            </div>
            <div className="digit">
              <span>12</span>
            </div>
            <div id="mickey"></div>
            <div className="right" id="hours"></div>
            <div className="left" id="minutes"></div>
          </div>
        </div>
      </Draggable>
    </div>
  );
}
