import React, { useState } from "react";
import Draggable from "react-draggable";
import Webcam from "react-webcam";

export default function WebcamModule() {
  const [on, setOn] = useState(false);
  const isChrome = navigator.userAgent.indexOf("Chrome") !== -1;

  return (
    <div>
      {/* {isChrome ? (
        <Draggable bounds="body">
          <div className="window">
            <div className="buttonCam">
              <button onClick={() => setOn(!on)}> Photo booth </button>
              <div className="dot"></div>
            </div>
            {on ? (
              <>
                <Webcam
                  mirrored={true}
                  height={260}
                  width={300}
                  screenshotQuality={0.03}
                />
              </>
            ) : null}
          </div>
        </Draggable>
      ) : null} */}
    </div>
  );
}

// //let img;
// let video;
// let detector;
// let detections = [];

// function preload() {
//   img = loadImage("dog_cat.jpg");
//   detector = ml5.objectDetector("cocossd");
// }

// function gotDetections(error, results) {
//   if (error) {
//     console.error(error);
//   }
//   detections = results;
//   detector.detect(video, gotDetections);
// }

// function setup() {
//   createCanvas(640, 480);
//   video = createCapture(VIDEO);
//   video.size(640, 480);
//   video.hide();
//   detector.detect(video, gotDetections);
// }

// function draw() {
//   image(video, 0, 0);
//   frameRate(20)
//   for (let i = 0; i < detections.length; i++) {
//     //   let object = detections[i];
//     //   stroke(255, 255, 255);
//     //   strokeWeight(0.7);
//     //   noFill();
//     //   rect(object.x, object.y, object.width, object.height);

//     //   noStroke();
//     //   fill(0, 0, 255);
//     //   textSize(40);
//     //   text(object.label, object.x, object.y);

//     //   // Rectangle
//     //   rectMode(CENTER);
//     //   stroke(0, 255, 105);
//     //   noFill();
//     //   rect(mouseX, mouseY, 30, 30);
//     // }

//     image(video, 0, 0);

//     for (let i = 0; i < detections.length; i++) {
//       let object = detections[i];
//       stroke(0, 0, 0);
//       fill(255, 255, 255, 10);
//       strokeWeight(0.8);

//       rect(object.x, object.y, object.width, object.height);
//       noStroke();
//       fill(255, 255, 255);
//       textSize(26);
//       text(object.label, object.x + 10, object.y - 15);

//       textSize(16);
//       //confidence
//       text(
//         "confidence_value " + Math.round(object.confidence * 100) + " %",
//         object.x + 10,
//         object.y + 45
//       );
//       //position
//       text("x_vlue " + Math.round(object.x), object.x + 10, object.y + 30);
//       text("y_value " + Math.round(object.y), object.x + 10, object.y + 15);
//     }
//   }
// }
