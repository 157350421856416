import React, { useEffect, useState } from "react";
import Digital from "./Digital";
import FFS from "./FFS";
import Mickey from "./Mickey";

export default function ClockModule() {
  // const clocks = ["digital", "ffs", "mickey"];
  const clocks = ["digital", "ffs"];
  const [clockPicker, setClockPicker] = useState();

  useEffect(() => {
    setClockPicker(clocks[Math.floor(Math.random() * clocks.length)]);
  }, []);
  return (
    <div className="utilityModule" utilityModule style={{ cursor: "pointer" }}>
      {clockPicker === "ffs" ? <FFS x={window.innerWidth - 300} y={0} /> : null}
      {clockPicker === "digital" ? (
        <Digital x={window.innerWidth - 300} y={50} />
      ) : null}
      {clockPicker === "mickey" ? (
        <Mickey x={window.innerWidth - 400} y={50} />
      ) : null}
    </div>
  );
}
