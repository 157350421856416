import React from "react";
import Posts from "./Posts";

export default function PostSection() {
  return (
    <>
      <div className="container" id="selected">
        <section>
          <hr />
          <p className="fixedP">
            Interno 9 is a studio that operates at the intersection of design
            and coding, where the worlds of digital and physical space come
            together to create immersive experiences.
            <br />
            <br />
            We specialize in web development, utilizing cutting-edge technology
            and techniques to build responsive, user-friendly websites that
            deliver exceptional performance and functionality.
          </p>
        </section>

        <section id="info">
          <hr />
          <Posts />
        </section>
      </div>
    </>
  );
}
