import Swipe from "./Swipe";

export default function Post(props) {
  return (
    <div className="post">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p>{props.description}</p>
        <a style={{ color: "#b6b6b6" }} href={props.url} target="_blank">
          <span>{props.urlClean}</span>
        </a>
      </div>
      <Swipe imgs={props.imgs} />
    </div>
  );
}
