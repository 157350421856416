import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import SwiperCore, { EffectFade, EffectCards } from "swiper";
SwiperCore.use([EffectFade, EffectCards]);

export default function Messages(props) {
  return (
    <div className={props.who === "mine" ? "mine messages" : "yours messages"}>
      {props.messages.map((message, index) => (
        <div
          key={index}
          className={`message ${
            index === props.messages.length - 1 ? "last" : ""
          }`}
        >
          {message}
        </div>
      ))}

      <Swiper
        effect={"cards"}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper mySwiperMMS"
      >
        {props.imgs?.map((img, index) => (
          <SwiperSlide key={index}>
            <img src={img} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
