import React, { useRef, useEffect } from "react";
import p5 from "p5";
import Draggable from "react-draggable";

function Album() {
  const myRef = useRef();

  useEffect(() => {
    let myp5 = new p5(sketch, myRef.current);

    function sketch(p5) {
      p5.setup = () => {
        p5.createCanvas(300, 300);
      };

      p5.draw = () => {
        p5.frameRate(1.5);
        p5.background(255);

        for (let i = 0; i < 6; i++) {
          p5.rect(parseInt(p5.random(290)), parseInt(p5.random(290)), 6, 6);
          p5.fill(p5.random(255), p5.random(255), p5.random(255));
          p5.noStroke();
        }
      };
    }

    return () => {
      myp5.remove();
    };
  }, []);

  return (
    <>
      <Draggable bounds="body">
        <div ref={myRef} className="window" />
      </Draggable>
    </>
  );
}

export default Album;
