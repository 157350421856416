import { useEffect, useRef, useState } from "react";
import ClockModule from "./ClockModule";
import Textarea from "./Textarea";
import Site from "./Site";
import WebcamModule from "./webcams/WebcamModule";
import Album from "./webcams/Album";
import siteData from "../site.json";
import anime from "animejs";

let windowDimention = {
  width: window.innerWidth,
  height: window.innerHeight,
};

function randomX() {
  return Math.random() * (windowDimention.width - 500);
}

function randomY() {
  return Math.random() * (windowDimention.height - 600);
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max + 1);
}

export default function Hero() {
  const [randomSites, setRandomSites] = useState([]);

  // window refresh
  let timer;

  const handleRefresh = () => {
    clearTimeout(timer);
    timer = setTimeout(() => window.location.reload(), 1000);
  };

  // triggered by resizing
  useEffect(() => {
    // Change condition: Detect if it's a mobile device or iPhone
    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
    const isiPhone = /iPhone/i.test(navigator.userAgent);

    if (!isMobileDevice && !isiPhone) {
      const handleResize = () => {
        clearTimeout(timer);
        timer = handleRefresh();
      };

      window.addEventListener("resize", handleResize);

      return () => {
        clearTimeout(timer);
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  useEffect(() => {
    const getRandomSites = () => {
      // a lot websites
      if (windowDimention.width > 1200) {
        const shuffledSites = siteData.sort(() => 0.5 - Math.random());
        const selectedSites = shuffledSites.slice(0, 4);
        setRandomSites(selectedSites);
      }

      // few webs
      if (windowDimention.width < 1200) {
        const shuffledSites = siteData.sort(() => 0.5 - Math.random());
        const selectedSites = shuffledSites.slice(0, 2);
        setRandomSites(selectedSites);
      }
    };

    getRandomSites();
  }, [windowDimention.width]);

  // domino fade in animation
  const animationDomino = useRef(null);
  useEffect(() => {
    if (animationDomino.current) {
      const paperChildren = Array.from(animationDomino.current.children);

      anime({
        targets: paperChildren,
        opacity: [0, 1],
        translateY: ["15%", 1],
        translateX: ["3%", 1],
        duration: 800,
        easing: "easeOutQuart",
        delay: (el, i) => i * 500,
      });
    }
  }, []);

  return (
    <div style={{ maxWidth: "100vw" }}>
      {windowDimention.width > 700 ? (
        <section
          className="hero"
          id="hero"
          ref={animationDomino}
          style={{ padding: "1em" }}
        >
          <span onClick={handleRefresh}>
            <span className="material-symbols-outlined refresh">replay</span>
          </span>

          {/* <Textarea
            x={Math.random() * 100}
            y={0}
            text={
              "Interno 9 is a studio that operates at the intersection of design and coding, where the worlds of digital and physical space come together to create immersive experiences."
            }
          /> */}
          <ClockModule />
          {/* <Album /> */}

          {randomSites.map((data, index) => (
            <Site
              key={index}
              ref={animationDomino}
              title={data.title}
              urlClean={data.urlClean}
              url={data.url}
              imgs={data.imgs}
              vids={data.vids}
              x={randomX()}
              y={randomY()}
            />
          ))}
          {/* iframes */}
          {getRandomInt(3) === 1 ? (
            <Site
              iframe={true}
              title={"Jellyfish - Badvisor.io"}
              urlClean={""}
              url={"https://app.badvisor.io/nicov3/modules/jellyfish"}
              imgs={null}
              x={randomX()}
              y={randomY()}
            />
          ) : null}
          {getRandomInt(3) === 2 ? (
            <Site
              iframe={true}
              title={"Lume"}
              urlClean={"lume-bdzh"}
              url={"https://lume.interno9.ch"}
              imgs={null}
              x={randomX()}
              y={randomY()}
            />
          ) : null}
          {getRandomInt(3) === 3 ? (
            <Site
              iframe={true}
              title={"Badvisor.io"}
              urlClean={""}
              url={"https://app.badvisor.io/nicov3/modules/map"}
              imgs={null}
              x={randomX()}
              y={randomY()}
            />
          ) : null}
          {/* works only in beeg screen */}
          {/* {getRandomInt(4) === 4 ? (
          <Site
            iframe={true}
            title={"Polar coordinates"}
            urlClean={""}
            url={"https://interno9.netlify.app/liner/"}
            imgs={null}
            x={randomX()}
            y={randomY()}
          />
        ) : null} */}
          <WebcamModule />
          {/* <ObjectDetection /> */}
        </section>
      ) : null}
    </div>
  );
}
