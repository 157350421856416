// import React, { useRef, useEffect, useState } from "react";

// const Sketch = () => {
//   const canvasRef = useRef(null);
//   let numLines;

//   function x1(t) {
//     return Math.sin(t / 40) * 350 + Math.sin(t);
//   }
//   function x2(t) {
//     return Math.sin(t / 17) * 390 + Math.sin(t);
//   }
//   function y1(t) {
//     return Math.sin(t / 12) * 290 + Math.sin(t);
//   }

//   function y2(t) {
//     return Math.cos(t / 20) * 430 + Math.cos(t);
//   }

//   const initializeFields = () => {
//     numLines = 350;
//   };

//   const addLines = () => {
//     numLines = numLines + 50;
//   };

//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const p = new window.p5((p) => {
//       p.setup = () => {
//         p.createCanvas(window.innerWidth, window.innerHeight);
//         initializeFields();
//       };

//       p.draw = () => {
//         p.background(255, 255, 255, 200);
//         p.stroke(0, 0, 255, 40);
//         p.translate(p.width / 2, p.height / 2);
//         p.strokeWeight(0.8);

//         for (let i = 0; i < numLines; i++) {
//           p.line(
//             x1(p.frameCount / 30 + i * p.sin(5)),
//             y1(p.frameCount / 22 + i * p.sin(9)),
//             x2(p.frameCount / 20 + i * p.sin(9)),
//             y2(p.frameCount / 20 + i * p.sin(9))
//           );
//         }
//       };
//     }, canvas);
//   }, []);

//   return (
//     <div>
//       <h1
//         style={{
//           position: "absolute",
//           left: "18px",
//           top: "32px",
//         }}
//       >
//         Interno 9
//       </h1>
//       <p
//         style={{
//           position: "absolute",
//           left: "18px",
//           top: "78px",
//           maxWidth: "350px",
//           fontWeight: "bold",
//           // backdropFilter: "blur(4px) ",
//         }}
//       >
//         Blending web developement, computer graphics and UI design to create
//         immmersive websites for clients of any type. Based in Switzerland.
//       </p>
//       <div
//         style={{ maxWidth: "calc(100vw - 2em)", boxSizing: "border-box" }}
//         ref={canvasRef}
//         onClick={() => addLines()}
//       ></div>
//     </div>
//   );
// };

// export default Sketch;

import React from "react";

export default function Sketch() {
  return (
    <div>
      <h1
        style={{
          position: "absolute",
          left: "18px",
          top: "32px",
        }}
      >
        Interno 9
      </h1>
      <p
        style={{
          position: "absolute",
          left: "18px",
          top: "78px",
          maxWidth: "350px",
        }}
      >
        Blending web developement, computer graphics and UI design to create
        immmersive websites for clients of any type. Based in Switzerland.
      </p>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <br />
    </div>
  );
}
